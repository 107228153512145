/* Error pages */
.error-title {
    margin-bottom: 20px;
    @apply text-5xl;
}

.error-description {
    font-weight: 300;
    margin-bottom: 20px;
    @apply text-3xl;
}

.error-bot {
    width: 80%;
}
